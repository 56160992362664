<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <h3>
            Agenda
          </h3>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Segunda Feira"
              label-for="segundafeira"
            >
              <b-form-input
                id="segundafeira"
                v-model="segundaAgenda"
                placeholder="Segunda Feira"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Terça Feira"
              label-for="tercafeira"
            >
              <b-form-input
                id="tercafeira"
                v-model="tercaAgenda"
                placeholder="Terça Feira"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Quarta Feira"
              label-for="quartafeira"
            >
              <b-form-input
                id="quartafeira"
                v-model="quartaAgenda"
                placeholder="Quarta Feira"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Quinta Feira"
              label-for="quintafeira"
            >
              <b-form-input
                id="quintafeira"
                v-model="quintaAgenda"
                placeholder="Quinta Feira"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Sexta Feira"
              label-for="sextafeira"
            >
              <b-form-input
                id="sextafeira"
                v-model="sextaAgenda"
                placeholder="Sexta Feira"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Sabado"
              label-for="sabado"
            >
              <b-form-input
                id="sabado"
                v-model="sabadoAgenda"
                placeholder="Sabado"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Domingo"
              label-for="domingo"
            >
              <b-form-input
                id="domingo"
                v-model="domingoAgenda"
                placeholder="Domingo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button
              variant="success"
              class="mb-75"

              fixed
              block
            > Salvar Agenda
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      id: null,
      segundaAgenda: '',
      tercaAgenda: '',
      quartaAgenda: '',
      quintaAgenda: '',
      sextaAgenda: '',
      sabadoAgenda: '',
      domingoAgenda: '',
      mostraPopUp: true,
      data: {},
      dadositem: {},
    }
  },
  created() {
  },
  methods: {

    EditarDados(item) {
      localStorage.setItem('dados_empresa', JSON.stringify(item))
      const dados = JSON.parse(localStorage.getItem('dados_empresa'))
      this.id = dados.id
      this.nomeEmpresa = dados.nome
      this.categoriaTipo = dados.status
      if (this.categoriaTipo === 1) {
        this.categoriaTipo = { text: 'Ativo' }
      } else {
        this.categoriaTipo = { text: 'Inativo' }
      }
      this.mostraPopUp = true
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },

    SalvarDados() {
      if (this.categoriaTipo === null) {
        this.Notificacao('danger', 'Selecione o Status da Empresa')
        return
      }
      const obj = this.ZerarCampos()
      if (this.id === null) {
        // eslint-disable-next-line no-unused-vars
        this.$http.post('admin/empresas', obj).then(resp => {
          this.mostraPopUp = false
          this.reloadTable()
        })
        return
      }
      this.$http.put(`admin/empresas/${this.id}`, obj).then(resp => {
        this.data = resp.data
        this.reloadTable()
      })
    },
    editDados(item) {
      localStorage.setItem('empresa', JSON.stringify(item))
      this.$router.push({
        name: 'app-membros',
      })
    },
  },
}
</script>

<style scoped>

</style>
